import React, { useEffect, useRef, useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { getAllPrizes, getRandomPrize } from '../../services/PrizesService';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { accountService } from '../../_services/account_service';
import RouletteResult from './RouletteResult';
import PrizesDTO from '../../model/prizes/PrizesDTO';

export default function RouletteGame() {

    const [mustSpin, setMustSpin] = useState( false );
    const [showForm, setShowForm] = useState( false );
    const [prizeNumber, setPrizeNumber] = useState();
    const [prizes, setPrizes] = useState( [] );
    const [prizeWon, setPrizeWon] = useState( new PrizesDTO() );
    const disableBtn = useRef( false );
    const navigate = useNavigate();

    const flag = useRef( false );

    let email = accountService.getEmail();
    const { t } = useTranslation();





    /* DESACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    /*useEffect( () => {
        navigate( '/game/choixdujeu' );
    }, [] );
*/
    /* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    useEffect( () => {
        (async () => {
            if ( flag.current === false ) {
                try {
                    let result = await getAllPrizes( email );
                    let newData;
                    if ( !result.error ) {
                        newData = result.data.map( item => ({ option: item.label }) );
                        setPrizes( newData );
                    } else {
                        // Gérer l'erreur ici
                        console.error( 'Erreur lors de la récupération des prix:', result.error );
                    }

                } catch ( error ) {
                    // Gérer les erreurs d'exécution ici
                    console.error( 'Erreur lors de l\'exécution de la requête:', error );
                }
            }
            return () => flag.current = true;
        })();
    }, [] );

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'rgba(57, 106, 252, 1)'
    };

    const handleSpinClick = async () => {
        if ( !mustSpin ) {
            disableBtn.current = true;
            await generateRandomPrize();
            setMustSpin( true );
        }
    };

    const generateRandomPrize = async () => {
        let prize = await getRandomPrize( email );

        if ( !prize.error ) {
            setPrizeWon( { ...prizeWon, id: prize.data.id, label: prize.data.label } );
            const matchingIndex = prizes.findIndex( item => item.option === prize.data.label );
            if ( matchingIndex !== -1 ) {
                console.log( `La correspondance est trouvée à l'indice ${matchingIndex}.` );
                setPrizeNumber( matchingIndex );
            } else {
                navigate( '/game/choixdujeu' );
                console.log( 'Aucune correspondance trouvée.' );
            }
        } else {
            navigate( '/game/choixdujeu' );
        }
    };

    return (
        <>
            {prizes.length !== 0 ?
                <div style={containerStyle}>
                    {showForm ?
                        <RouletteResult prizeWon={prizeWon} email={email}/> :
                        <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
                              sx={{ margin: '10px' }}>

                            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left', marginBottom: '20px' }}>
                                <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                                          onClick={() => navigate( '/game/choixdujeu' )}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} xl={3} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Wheel
                                    mustStartSpinning={mustSpin}
                                    prizeNumber={prizeNumber}
                                    data={prizes}
                                    outerBorderColor={['#f2f2f2']}
                                    outerBorderWidth={[10]}
                                    innerBorderColor={['#f2f2f2']}
                                    radiusLineColor={['#dedede']}
                                    radiusLineWidth={[3]}
                                    textColors={['#ffffff']}
                                    fontSize={[22]}
                                    backgroundColors={[
                                        '#F22B35',
                                        '#F99533',
                                        '#24CA69',
                                        '#514E50',
                                        '#46AEFF',
                                        '#9145B7'
                                    ]}
                                    onStopSpinning={() => {
                                        setMustSpin( false );
                                        const timer = setTimeout( () => {
                                            setShowForm( true );
                                        }, 1500 );

                                        return () => clearTimeout( timer );

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ marginTop: '20px' }}>
                                {!disableBtn.current ?
                                    <Button onClick={handleSpinClick}
                                            sx={{
                                                width: '274px',
                                                height: '45px',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                gap: '10px',
                                                background: 'rgba( 243, 230, 0, 1 )',
                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                                '&:hover': {
                                                    color: 'rgba( 243, 230, 0, 1 )',
                                                },
                                            }}>
                                        {t( 'game.label18' )}
                                    </Button> :
                                    null
                                }
                            </Grid>
                        </Grid>
                    }
                </div>
                : null}
        </>
    );
};
