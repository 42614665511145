import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
// Connecte le backend (charge les traductions à partir de fichiers JSON)
.use(HttpApi)
// Détecte la langue de l'utilisateur
.use(LanguageDetector)
// Connecte i18next à React
.use(initReactI18next)
.init({
    // Si la langue n'est pas disponible, utilisez cette langue par défaut
    fallbackLng: 'fr',

    // Chaîne utilisée pour séparer les clés pour la traduction
    nsSeparator: ':',
    keySeparator: '.',

    // Configuration pour charger les traductions
    backend: {
        // Chemin d'accès aux traductions
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    // Configuration pour la détection de la langue
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'],
    },
});

export default i18n;
