export default class ClientsDTO {
    id;
    email;
    firstname;
    lastname;
    phone;
    address;
    postalCode;
    gender;
    consentStatus;
    parentalConsent;
    dateOfBirth;
    friterieName;
    friteriePostalCode;
    createdAt;
    updateAt;

    constructor( data ) {
        if ( data ) {
            this.id = data.id;
            this.email = data.email;
            this.firstname = data.firstname;
            this.lastname = data.lastname;
            this.phone = data.phone;
            this.address = data.address;
            this.postalCode = data.postalCode;
            this.gender = data.gender;
            this.consentStatus = data.consentStatus;
            this.parentalConsent = data.parentalConsent;
            this.dateOfBirth = data.dateOfBirth;
            this.friterieName = data.friterieName;
            this.friteriePostalCode = data.friteriePostalCode;
            this.createdAt = data.createdAt;
            this.updateAt = data.updateAt;
        }
    }
}
