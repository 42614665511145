import React, { useEffect, useRef, useState } from 'react';
import { findAllColoringPictures } from '../../services/ColoringPicturesService';
import { accountService } from '../../_services/account_service';
import { ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';

const PaintbrushPicturesChoices = () => {

    const [data, setData] = useState( [] );
    const [count, setCount] = useState( 0 );
    const skipPageResetRef = useRef();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const flag = useRef( false );

    useEffect( () => {
        (async () => {
            if ( flag.current === false ) {

                let result = await findAllColoringPictures( accountService.getEmail() );
                if ( !result.error ) {
                    skipPageResetRef.current = true;
                    setCount( result.data.totalElements );
                    setData( result.data.content ? result.data.content : [] );
                }
            }
            return () => flag.current = true;
        })();
    }, [] );

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'rgba(57, 106, 252, 1)'
    };

    const handleImageClick = ( url ) => {
        navigate( '/game/grandart/jeu', { state: { imageUrl: url } } );
    };

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '10px' }}
                  flexDirection="column">
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                    <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                              onClick={() => navigate( '/game/choixdujeu' )}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '34px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '38px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '42px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '46px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '50px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >{t( 'game.label7' )}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <ImageList sx={{
                        width: { xs: 350, sm: 500 },
                        height: { xs: 510, sm: 520 }
                    }}
                               cols={2} gap={8}>
                        {data.map( ( item ) => (
                            <ImageListItem key={item.url}>
                                <img
                                    src={`${item.url}?w=161&fit=crop&auto=format`}
                                    srcSet={`${item.url}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                    onClick={() => handleImageClick( item.url )}
                                    alt={item.label}
                                    loading="lazy"
                                />
                                <ImageListItemBar title={item.label} position="below" style={{
                                    color: 'white',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    background: 'rgba(0, 0, 0, 0.5)'
                                }}/>
                            </ImageListItem>
                        ) )}
                    </ImageList>
                </Grid>
            </Grid>
        </div>

    );
};

export default PaintbrushPicturesChoices;
