import Axios from "../_services/caller.service";

export async function findAllColoringPictures(email) {
    try {
        return await Axios.get(`/api/v1/public/coloring-pictures?email=${email}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
    } catch (e) {
        if (e.response) {
            // Le serveur a répondu avec un statut en dehors de la plage [200, 299]
            return {
                error: true,
                statusCode: e.response.status,
                message: e.response.data
            };
        } else {
            // Quelque chose s'est produit lors de la mise en place de la demande qui a déclenché une erreur
            return {
                error: true,
                message: e.message
            };
        }
    }
}