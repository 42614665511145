const questions = [
    {
        text: 'questions[0].text',
        picture: `${process.env.PUBLIC_URL}/quiz/1-atomium.webp`,
        options: [
            { id: 0, text: 'questions[0].options[0]', isCorrect: true },
            { id: 1, text: 'questions[0].options[1]', isCorrect: false },
            { id: 2, text: 'questions[0].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[1].text',
        picture: `${process.env.PUBLIC_URL}/quiz/2-drapeau-belge-_2_.webp`,
        options: [
            { id: 0, text: 'questions[1].options[0]', isCorrect: false },
            { id: 1, text: 'questions[1].options[1]', isCorrect: false },
            { id: 2, text: 'questions[1].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[2].text',
        picture: `${process.env.PUBLIC_URL}/quiz/3-atomium.webp`,
        options: [
            { id: 0, text: 'questions[2].options[0]', isCorrect: true },
            { id: 1, text: 'questions[2].options[1]', isCorrect: false },
            { id: 2, text: 'questions[2].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[3].text',
        picture: `${process.env.PUBLIC_URL}/quiz/4-statue-leopold-1.webp`,
        options: [
            { id: 0, text: 'questions[3].options[0]', isCorrect: true },
            { id: 1, text: 'questions[3].options[1]', isCorrect: false },
            { id: 2, text: 'questions[3].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[4].text',
        picture: `${process.env.PUBLIC_URL}/quiz/5- statue-leopold.webp`,
        options: [
            { id: 0, text: 'questions[4].options[0]', isCorrect: true },
            { id: 1, text: 'questions[4].options[1]', isCorrect: false },
            { id: 2, text: 'questions[4].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[5].text',
        picture: `${process.env.PUBLIC_URL}/quiz/6-mannekenpis.webp`,
        options: [
            { id: 0, text: 'questions[5].options[0]', isCorrect: true },
            { id: 1, text: 'questions[5].options[1]', isCorrect: false },
            { id: 2, text: 'questions[5].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[6].text',
        picture: `${process.env.PUBLIC_URL}/quiz/7-bois-du-cazier.webp`,
        options: [
            { id: 0, text: 'questions[6].options[0]', isCorrect: true },
            { id: 1, text: 'questions[6].options[1]', isCorrect: false },
            { id: 2, text: 'questions[6].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[7].text',
        picture: `${process.env.PUBLIC_URL}/quiz/8-louve.webp`,
        options: [
            { id: 0, text: 'questions[7].options[0]', isCorrect: false },
            { id: 1, text: 'questions[7].options[1]', isCorrect: false },
            { id: 2, text: 'questions[7].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[8].text',
        picture: `${process.env.PUBLIC_URL}/quiz/9-mercator.webp`,
        options: [
            { id: 0, text: 'questions[8].options[0]', isCorrect: false },
            { id: 1, text: 'questions[8].options[1]', isCorrect: false },
            { id: 2, text: 'questions[8].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[9].text',
        picture: `${process.env.PUBLIC_URL}/quiz/10-lion-de-waterloo.webp`,
        options: [
            { id: 0, text: 'questions[9].options[0]', isCorrect: false },
            { id: 1, text: 'questions[9].options[1]', isCorrect: false },
            { id: 2, text: 'questions[9].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[10].text',
        picture: `${process.env.PUBLIC_URL}/quiz/11-drapeau-belge.webp`,
        options: [
            { id: 0, text: 'questions[10].options[0]', isCorrect: true },
            { id: 1, text: 'questions[10].options[1]', isCorrect: false },
            { id: 2, text: 'questions[10].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[11].text',
        picture: `${process.env.PUBLIC_URL}/quiz/12-drapeau-belge-_2_.webp`,
        options: [
            { id: 0, text: 'questions[11].options[0]', isCorrect: true },
            { id: 1, text: 'questions[11].options[1]', isCorrect: false },
            { id: 2, text: 'questions[11].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[12].text',
        picture: `${process.env.PUBLIC_URL}/quiz/13-drapeau-belge-_2_.webp`,
        options: [
            { id: 0, text: 'questions[12].options[0]', isCorrect: false },
            { id: 1, text: 'questions[12].options[1]', isCorrect: false },
            { id: 2, text: 'questions[12].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[13].text',
        picture: `${process.env.PUBLIC_URL}/quiz/14-frites.webp`,
        options: [
            { id: 0, text: 'questions[13].options[0]', isCorrect: true },
            { id: 1, text: 'questions[13].options[1]', isCorrect: false },
            { id: 2, text: 'questions[13].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[14].text',
        picture: `${process.env.PUBLIC_URL}/quiz/15-snaky-personnage-1.webp`,
        options: [
            { id: 0, text: 'questions[14].options[0]', isCorrect: true },
            { id: 1, text: 'questions[14].options[1]', isCorrect: false },
            { id: 2, text: 'questions[14].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[15].text',
        picture: `${process.env.PUBLIC_URL}/quiz/16-snaky-personnage-1.webp`,
        options: [
            { id: 0, text: 'questions[15].options[0]', isCorrect: true },
            { id: 1, text: 'questions[15].options[1]', isCorrect: false },
            { id: 2, text: 'questions[15].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[16].text',
        picture: `${process.env.PUBLIC_URL}/quiz/17-diable-rouge.webp`,
        options: [
            { id: 0, text: 'questions[16].options[0]', isCorrect: false },
            { id: 1, text: 'questions[16].options[1]', isCorrect: false },
            { id: 2, text: 'questions[16].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[17].text',
        picture: `${process.env.PUBLIC_URL}/quiz/18-carbonnade-_3_.webp`,
        options: [
            { id: 0, text: 'questions[17].options[0]', isCorrect: true },
            { id: 1, text: 'questions[17].options[1]', isCorrect: false },
            { id: 2, text: 'questions[17].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[18].text',
        picture: `${process.env.PUBLIC_URL}/quiz/19-carbonnade-_2_.webp`,
        options: [
            { id: 0, text: 'questions[18].options[0]', isCorrect: false },
            { id: 1, text: 'questions[18].options[1]', isCorrect: true },
            { id: 2, text: 'questions[18].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[19].text',
        picture: `${process.env.PUBLIC_URL}/quiz/20- frites-_2_.webp`,
        options: [
            { id: 0, text: 'questions[19].options[0]', isCorrect: false },
            { id: 1, text: 'questions[19].options[1]', isCorrect: false },
            { id: 2, text: 'questions[19].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[20].text',
        picture: `${process.env.PUBLIC_URL}/quiz/21-hamburger.webp`,
        options: [
            { id: 0, text: 'questions[20].options[0]', isCorrect: true },
            { id: 1, text: 'questions[20].options[1]', isCorrect: false },
            { id: 2, text: 'questions[20].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[21].text',
        picture: `${process.env.PUBLIC_URL}/quiz/22-durum.webp`,
        options: [
            { id: 0, text: 'questions[21].options[0]', isCorrect: false },
            { id: 1, text: 'questions[21].options[1]', isCorrect: true },
            { id: 2, text: 'questions[21].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[22].text',
        picture: `${process.env.PUBLIC_URL}/quiz/23-poche-pita-1.webp`,
        options: [
            { id: 0, text: 'questions[22].options[0]', isCorrect: false },
            { id: 1, text: 'questions[22].options[1]', isCorrect: false },
            { id: 2, text: 'questions[22].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[23].text',
        picture: `${process.env.PUBLIC_URL}/quiz/24-frites.webp`,
        options: [
            { id: 0, text: 'questions[23].options[0]', isCorrect: true },
            { id: 1, text: 'questions[23].options[1]', isCorrect: false },
            { id: 2, text: 'questions[23].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[24].text',
        picture: `${process.env.PUBLIC_URL}/quiz/25-drapeau-belge-_2_.webp`,
        options: [
            { id: 0, text: 'questions[24].options[0]', isCorrect: false },
            { id: 1, text: 'questions[24].options[1]', isCorrect: false },
            { id: 2, text: 'questions[24].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[25].text',
        picture: `${process.env.PUBLIC_URL}/quiz/26-drapeau-belge.webp`,
        options: [
            { id: 0, text: 'questions[25].options[0]', isCorrect: true },
            { id: 1, text: 'questions[25].options[1]', isCorrect: false },
            { id: 2, text: 'questions[25].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[26].text',
        picture: `${process.env.PUBLIC_URL}/quiz/27-Kryspichix.webp`,
        options: [
            { id: 0, text: 'questions[26].options[0]', isCorrect: true },
            { id: 1, text: 'questions[26].options[1]', isCorrect: false },
            { id: 2, text: 'questions[26].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[27].text',
        picture: `${process.env.PUBLIC_URL}/quiz/28-Krokycroq.webp`,
        options: [
            { id: 0, text: 'questions[27].options[0]', isCorrect: true },
            { id: 1, text: 'questions[27].options[1]', isCorrect: false },
            { id: 2, text: 'questions[27].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[28].text',
        picture: `${process.env.PUBLIC_URL}/quiz/29-hamburger.webp`,
        options: [
            { id: 0, text: 'questions[28].options[0]', isCorrect: true },
            { id: 1, text: 'questions[28].options[1]', isCorrect: false },
            { id: 2, text: 'questions[28].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[29].text',
        picture: `${process.env.PUBLIC_URL}/quiz/30-carbonnade.webp`,
        options: [
            { id: 0, text: 'questions[29].options[0]', isCorrect: true },
            { id: 1, text: 'questions[29].options[1]', isCorrect: false },
            { id: 2, text: 'questions[29].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[30].text',
        picture: `${process.env.PUBLIC_URL}/quiz/31-vol-au-vent.webp`,
        options: [
            { id: 0, text: 'questions[30].options[0]', isCorrect: false },
            { id: 1, text: 'questions[30].options[1]', isCorrect: true },
            { id: 2, text: 'questions[30].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[31].text',
        picture: `${process.env.PUBLIC_URL}/quiz/32-gyros-porc.webp`,
        options: [
            { id: 0, text: 'questions[31].options[0]', isCorrect: false },
            { id: 1, text: 'questions[31].options[1]', isCorrect: false },
            { id: 2, text: 'questions[31].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[32].text',
        picture: `${process.env.PUBLIC_URL}/quiz/33-carbonnade-_2_.webp`,
        options: [
            { id: 0, text: 'questions[32].options[0]', isCorrect: false },
            { id: 1, text: 'questions[32].options[1]', isCorrect: true },
            { id: 2, text: 'questions[32].options[2]', isCorrect: false },
        ],
    },
    {
        text: 'questions[33].text',
        picture: `${process.env.PUBLIC_URL}/quiz/34-steak-hache-1.webp`,
        options: [
            { id: 0, text: 'questions[33].options[0]', isCorrect: false },
            { id: 1, text: 'questions[33].options[1]', isCorrect: false },
            { id: 2, text: 'questions[33].options[2]', isCorrect: true },
        ],
    },
    {
        text: 'questions[34].text',
        picture: `${process.env.PUBLIC_URL}/quiz/35-snaky-personnage-1.webp`,
        options: [
            { id: 0, text: 'questions[34].options[0]', isCorrect: true },
            { id: 1, text: 'questions[34].options[1]', isCorrect: false },
            { id: 2, text: 'questions[34].options[2]', isCorrect: false },
        ],
    },

];

export default questions;
