import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import questions from '../../assets/questions/questions';
import QuizzResult from './QuizzResult';
import { useTranslation } from 'react-i18next';

export default function QuizzGame() {

    const [showResults, setShowResults] = useState( false );
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState( 0 );
    const [score, setScore] = useState( 0 );
    const { t } = useTranslation();
    const [isImageLoaded, setIsImageLoaded] = useState( false );

    const buttonStyle = {
        width: '274px',
        height: '45px',
        borderRadius: '5px',
        background: 'rgba(255, 255, 255, 1)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    };

    const optionTextStyle = {
        color: 'rgba(25, 60, 141, 1)', // Changer la couleur du texte en blanc
        fontWeight: '700',
        padding: '8px', // Ajouter un espacement intérieur pour rendre le texte plus lisible
        borderRadius: '5px', // Ajouter des coins arrondis
        display: 'inline-block', // Ajouter cette propriété pour que le background s'applique correctement
    };

    const navigate = useNavigate();

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'rgba(57, 106, 252, 1)'
    };

    const colorTxtQuizz = {
        marginTop: '10px',
        color: 'white'
    };

    const generateRandomQuestions = () => {
        const questionsSelected = [];

        while ( questionsSelected.length < 5 ) {
            const randomIndex = Math.floor( Math.random() * questions.length );
            if ( !questionsSelected.includes( randomIndex ) ) {
                questionsSelected.push( randomIndex );
            }
        }

        return questionsSelected;
    };

    const randomQuestions = generateRandomQuestions();

    const currentQuestions = randomQuestions.map( ( index ) => {
        // Récupérer chaque question basée sur l'index
        const question = questions[ index ];

        // Ajouter le champ 'randomIndex' à la question
        return {
            ...question,
            randomIndex: index,
        };
    } );

    useEffect( () => {
        setIsImageLoaded( false ); // Réinitialiser l'état de chargement de l'image à chaque changement de question
    }, [currentQuestionIndex] );

    /* A possible answer was clicked */
    const optionClicked = ( isCorrect ) => {
        // Increment the score
        if ( isCorrect ) {
            setScore( score + 1 );
        }

        if ( currentQuestionIndex + 1 < randomQuestions.length ) {
            setCurrentQuestionIndex( currentQuestionIndex + 1 );
        } else {
            setShowResults( true );
        }
    };

    const onImageLoad = () => {
        setIsImageLoaded( true );
    };

    /* Resets the game back to default */
    const restartGame = () => {
        setScore( 0 );
        setCurrentQuestionIndex( 0 );
        setShowResults( false );
    };

    return (

        <div style={containerStyle}>
            {showResults ? (
                /* 4. Final Results */
                <QuizzResult
                    score={score}
                    questionLength={currentQuestions.length}
                    restartGame={restartGame}
                />

            ) : (
                <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
                      sx={{ margin: '10px' }}>

                    <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                        <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                                  onClick={() => navigate( '/game/choixdujeu' )}/>
                    </Grid>

                    {/*5. Question Card*/}
                    <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                        {/* Current Question */}
                        <Typography variant="subtitle2" style={colorTxtQuizz}>
                            {t( 'game.label8' )}: {currentQuestionIndex + 1} {t( 'game.label9' )} {currentQuestions.length}
                        </Typography>

                        {!isImageLoaded && <p>{t( 'game.label24' )}</p>}
                        {currentQuestions[ currentQuestionIndex ].picture && (
                            <img src={currentQuestions[ currentQuestionIndex ].picture}
                                 alt=""
                                 style={{
                                     width: '100%',
                                     height: 'auto',
                                     marginTop: '10px',
                                     display: isImageLoaded ? 'block' : 'none'
                                 }}
                                 onLoad={onImageLoad}
                            />
                        )}

                        {isImageLoaded && (
                            <>
                                <Typography variant="h1" style={colorTxtQuizz}
                                            sx={{
                                                fontSize: {
                                                    xs: '32px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                                    sm: '34px', // Taille de police pour les écrans de taille 'sm' (small)
                                                    md: '38px', // Taille de police pour les écrans de taille 'md' (medium)
                                                    lg: '40px', // Taille de police pour les écrans de taille 'lg' (large)
                                                    xl: '42px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                                },
                                            }}>
                                    {t( `questions.${currentQuestions[ currentQuestionIndex ].randomIndex}.text` )}
                                </Typography>

                                {/* List of possible answers */}
                                <Grid container spacing={2} direction="column" style={{ marginTop: '20px' }}>
                                    {currentQuestions[ currentQuestionIndex ].options.map( ( option, index ) => (
                                        <Grid item key={option.id}>
                                            <Button
                                                variant="contained"
                                                onClick={() => optionClicked( option.isCorrect )}
                                                style={buttonStyle}
                                            >
                                                <Typography variant="button" display="block" style={optionTextStyle}>
                                                    {t( `questions.${currentQuestions[ currentQuestionIndex ].randomIndex}.options.${index}` )}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    ) )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>)}
        </div>
    );
}
