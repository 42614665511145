import { useState } from 'react';

export const useMessageUtil = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const showMessage = (message, severity) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return {
        showAlert,
        alertMessage,
        alertSeverity,
        showMessage,
        handleCloseAlert,
    };
};
