import React from 'react';
import {Navigate} from "react-router-dom";
import {accountService} from "../_services/account_service";

const AuthGuard = ({children}) => {
    if (!accountService.isLogged()) {
        return <Navigate to={process.env.REACT_APP_URL_START}/>;
    }

    return (
        children
    );
};

export default AuthGuard;
