import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import SnakyFootLogo from '../../../assets/img/snakyFootLogo.png';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function GameOver() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { score } = location.state || { score: 0 };

    const handleRestart = () => {
        navigate( '/game/snakyfoot/game' );
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'rgba(57, 106, 252, 1)'
    };

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
                  sx={{ margin: '10px' }}>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                    <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                              onClick={() => navigate( '/game/choixdujeu' )}/>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                        <img src={SnakyFootLogo} alt="Logo"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Typography variant="h1"
                                    color="white"
                                    sx={{
                                        fontSize: {
                                            xs: '30px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                            sm: '32px', // Taille de police pour les écrans de taille 'sm' (small)
                                            md: '33px', // Taille de police pour les écrans de taille 'md' (medium)
                                            lg: '34px', // Taille de police pour les écrans de taille 'lg' (large)
                                            xl: '35px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                        },
                                    }}
                        >
                            {t( 'game.label27' )}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center', marginTop: '20px' }}>
                        <Typography variant="subtitle1"
                                    color="white">{t( 'game.label28' ) + score + t( 'game.label29' )}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>
                        <Button type="submit" variant="contained" onClick={handleRestart}
                                sx={{
                                    width: '274px',
                                    height: '45px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    gap: '10px',
                                    background: 'rgba( 243, 230, 0, 1 )',
                                    fontWeight: 'bold',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    '&:hover': {
                                        color: 'rgba( 243, 230, 0, 1 )',
                                    },
                                }}>
                            {t( 'game.label17' )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default GameOver;
