import './App.css';
import * as React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AuthGuard from "./_helpers/AuthGuard";
import GameRouter from "./views/Game/GameRouter";
import StartRouter from "./views/StartRouter";


function App() {

    return(
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<StartRouter/>}/>
                    <Route path="/game/*" element={
                        <AuthGuard>
                            <GameRouter/>
                        </AuthGuard>
                    }/>
                </Routes>
            </BrowserRouter>
        </div>
        )

}

export default App;


