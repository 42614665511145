import axios from "axios";

const Axios = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_API_URL_BACKEND
})

Axios.interceptors.response.use(undefined, error => {
    if (error.response.status === 401) {
        localStorage.removeItem('email');

        // eslint-disable-next-line no-undef
        window.location = process.env.REACT_APP_URL_START;
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export default Axios;