export default class ClientsPrizesDTO {
    id;
    email;
    company;
    vat;
    prizeID;
    address;
    phone;

    constructor( data ) {
        if ( data ) {
            this.id = data.id;
            this.email = data.email;
            this.company = data.company;
            this.vat = data.vat;
            this.prizeID = data.prizeID;
            this.address = data.address;
            this.phone = data.phone;
        }
    }
}
