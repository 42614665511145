import { createTheme } from '@mui/material';

const theme = createTheme( {
    typography: {
        fontFamily: 'Raleway, Arial, sans-serif',
        h1: {
            fontWeight: 700,
        },
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: 'white', // Couleur du texte du label
                    fontSize: '0.8rem', // Taille de police du label
                    fontWeight: 700, // Poids de la police du label
                },
                asterisk: {
                    color: 'white', // Changer la couleur de l'astérisque
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'white', // Couleur de la case à cocher non cochée
                    '&.Mui-checked': {
                        color: 'rgba(243, 230, 0, 1)', // Couleur de la case à cocher cochée
                    },
                }
            }
        }
    },
} );

export default theme;
