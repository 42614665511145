import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClientsPrizesDTO from '../../model/clientsPrizes/ClientsPrizesDTO';
import { useMessageUtil } from '../../utils/MessageUtil';
import { isEmpty, isNil } from 'lodash';
import { createClientsPrizes } from '../../services/PrizesService';
import { Alert, Button, FormControl, InputLabel, Snackbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import BootstrapInput from '../../component/BootstrapInput';

export default function RouletteResult( {
                                            prizeWon,
                                            email
                                        } ) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showAlert, alertMessage, alertSeverity, showMessage, handleCloseAlert } = useMessageUtil();
    const [clientsPrizes, setClientsPrizes] = useState( new ClientsPrizesDTO() );
    const [displayMessage, setDisplayMessage] = useState( false );

    /* DESACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
/*    useEffect( () => {
        navigate( '/game/choixdujeu' );
    }, [] );*/

    /* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    useEffect( () => {
        setClientsPrizes( { ...clientsPrizes, email: email, prizeID: prizeWon.id } );
    }, [] );

    const _saveClientPrizes = async ( e ) => {

        e.preventDefault();

        if ( isNil( clientsPrizes.company ) || isEmpty( clientsPrizes.company ) ) {
            showMessage( t( 'error.company_empty' ), 'error' );
            return;
        }

        if ( isNil( clientsPrizes.vat ) || isEmpty( clientsPrizes.vat ) ) {
            showMessage( t( 'error.vat_empty' ), 'error' );
            return;
        }

        if ( isNil( clientsPrizes.address ) || isEmpty( clientsPrizes.address ) ) {
            showMessage( t( 'error.address_empty' ), 'error' );
            return;
        }

        if ( isNil( clientsPrizes.phone ) || isEmpty( clientsPrizes.phone ) ) {
            showMessage( t( 'error.phone_empty' ), 'error' );
            return;
        }

        let result = await createClientsPrizes( clientsPrizes );
        if ( !result.error ) {
            setDisplayMessage( true );
        } else {
            // Show error message
            showMessage( t( 'error.prizes' ), 'error' );

            const timer = setTimeout( () => {
                window.location.reload();
            }, 5000 ); // 5000 millisecondes équivalent à 5 secondes

            // Nettoyer le timer si le composant est démonté avant le rafraîchissement
            return () => clearTimeout( timer );
        }
    };

    const handleEventCompanyChange = ( e ) => {
        setClientsPrizes( { ...clientsPrizes, company: e.target.value } );
    };
    const handleEventVatChange = ( e ) => {
        const sanitizedValue = e.target.value.replace( /[^a-zA-Z0-9]/g, '' );

        setClientsPrizes( { ...clientsPrizes, vat: sanitizedValue } );
    };
    const handleEventAddressChange = ( e ) => {
        setClientsPrizes( { ...clientsPrizes, address: e.target.value } );
    };
    const handleEventPhoneChange = ( e ) => {
        const sanitizedValue = e.target.value.replace( /[^0-9+]/g, '' );

        setClientsPrizes( { ...clientsPrizes, phone: sanitizedValue } );
    };

    return (
        <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
              sx={{ margin: '10px' }}>

            {/* Le composant Snackbar pour afficher l'alerte */}
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                          onClick={() => navigate( '/game/choixdujeu' )}/>
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '30px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '32px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '33px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '34px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '35px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >
                        {t( 'game.label19' )}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '30px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '32px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '33px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '34px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '35px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >
                        {displayMessage ? t( 'game.label23' ) + prizeWon.label : t( 'game.label20' ) + prizeWon.label}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Typography variant="subtitle1"
                                color="white">
                        {displayMessage ? t( 'game.label22' ) : t( 'game.label21' )}
                    </Typography>
                </Grid>
            </Grid>

            {!displayMessage ?
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <form>
                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.company' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clientsPrizes.company}
                                onChange={handleEventCompanyChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.vat' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clientsPrizes.vat}
                                onChange={handleEventVatChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.address' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clientsPrizes.address}
                                onChange={handleEventAddressChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <FormControl variant="standard" fullWidth>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.phone' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clientsPrizes.phone}
                                onChange={handleEventPhoneChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={_saveClientPrizes}
                                sx={{
                                    width: '274px',
                                    height: '45px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    gap: '10px',
                                    background: 'rgba( 243, 230, 0, 1 )',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        color: 'rgba( 243, 230, 0, 1 )',
                                    },
                                }}>
                                {t( 'common.submit' )}
                            </Button>
                        </Grid>
                    </form>
                </Grid> : null
            }

        </Grid>
    );
}
