import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PaintbrushGame from './PaintbrushGame';
import GameChoice from './GameChoice';
import Quizz from './Quizz';
import QuizzGame from './QuizzGame';
import Paintbrush from './Paintbrush';
import ErrorPage from '../ErrorPage';
import PaintbrushPicturesChoices from './PaintbrushPicturesChoices';
import RouletteGame from './RouletteGame';
import MainMenu from './foot/MainMenu';
import GameField from './foot/GameField';
import GameOver from './foot/GameOver';

const GameRouter = () => {
    return (
        <Routes>
            <Route index element={<GameChoice/>}/>
            <Route path="/grandart/jeu" element={<PaintbrushGame/>}/>
            <Route path="/choixdujeu" element={<GameChoice/>}/>
            <Route path="/choiximage" element={<PaintbrushPicturesChoices/>}/>
            <Route path="/grandquizz" element={<Quizz/>}/>
            <Route path="/grandquizz/jeu" element={<QuizzGame/>}/>
            <Route path="/grandart" element={<Paintbrush/>}/>
            <Route path="/roulette" element={<RouletteGame/>}/>
            <Route path="/snakyfoot/" element={<MainMenu/>}/>
            <Route path="/snakyfoot/game" element={<GameField/>}/>
            <Route path="/snakyfoot/game-over" element={<GameOver/>}/>
            <Route path="*" element={<ErrorPage/>}/>
        </Routes>
    );
};

export default GameRouter;
