import React, { useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import IconButton from '@mui/material/IconButton';
import DrawIcon from '@mui/icons-material/Draw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PaintbrushGame() {

    const location = useLocation();
    const imageToDraw = location.state.imageUrl;
    const { t } = useTranslation();

    const [currentColor, setCurrentColor] = useState( '#000000' );
    const [strokeWidth, setStrokeWidth] = useState( 5 );
    const [eraserWidth, setEraserWidth] = useState( 5 );
    const canvasIlis = useRef( null );

    const colorsArray = ['#000000', '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'white'
    };

    const navigate = useNavigate();

    const styles = {
        margin: 'auto',
    };

    const styleCirclePicker = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
        marginTop: '10px'
    };

    const iconColor = {
        color: 'rgba(25, 60, 141, 1)',
    };

    const handleColorChange = ( color ) => {
        setCurrentColor( color.hex );
        handleEraserModeClick( false );
    };

    const handleStrokeWidthChange = ( event ) => {
        setStrokeWidth( event.target.value );
    };

    const handleEraserWidthChange = ( event ) => {
        setEraserWidth( event.target.value );
    };

    const handleEraserModeClick = ( eraseMode ) => {
        canvasIlis.current.eraseMode( eraseMode );
    };

    const handleDownloadDraw = async () => {

        // Export the image as base64
        const base64Image = await canvasIlis.current.exportSvg();

        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent( base64Image );

        // Verify image is loaded before drawing on canvas
        image.onload = () => {
            // Create a new canvas to draw the final PNG image
            const imageCanvas = document.createElement( 'canvas' );
            imageCanvas.width = image.width;
            imageCanvas.height = image.height;

            const ctx = imageCanvas.getContext( '2d' );

            const backgroundImage = new Image();
            backgroundImage.crossOrigin = 'anonymous';
            backgroundImage.src = imageToDraw;

            backgroundImage.onload = () => {
                // Draw the background image on the canvas
                ctx.drawImage( backgroundImage, 0, 0, imageCanvas.width, imageCanvas.height );

                // Draw the SVG image on the canvas
                ctx.drawImage( image, 0, 0, imageCanvas.width, imageCanvas.height );

                // Convert the canvas to base64 data URL (PNG format)
                const dataURL = imageCanvas.toDataURL();

                // Create a link for downloading the PNG image
                const downloadLink = document.createElement( 'a' );
                downloadLink.href = dataURL;
                downloadLink.download = 'my_image.png';

                // Trigger the click on the link to start the download
                downloadLink.click();
            };

        };
    };
    return (
        <div style={containerStyle}>

            <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
                  sx={{ margin: '10px' }}>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                    <HomeIcon style={{ color: 'rgba(57, 106, 252, 1)', cursor: 'pointer' }}
                              onClick={() => navigate( '/game/choixdujeu' )}/>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} textAlign="center">
                    <ReactSketchCanvas
                        ref={canvasIlis}
                        style={styles}
                        width="100%"
                        height="500px"
                        strokeWidth={strokeWidth}
                        strokeColor={currentColor}
                        eraserWidth={eraserWidth}
                        backgroundImage={imageToDraw}
                        exportWithBackgroundImage={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3}
                      sx={{ textAlign: 'center' }}>
                    <IconButton
                        style={iconColor}
                        onClick={() => {
                            canvasIlis.current.undo();
                        }}
                    >
                        <UndoIcon/>
                    </IconButton>
                    <IconButton
                        style={iconColor}
                        onClick={() => {
                            canvasIlis.current.redo();
                        }}
                    >
                        <RedoIcon/>
                    </IconButton>

                    <div style={styleCirclePicker}>
                        <CirclePicker
                            color={currentColor}
                            onChange={handleColorChange}
                            colors={colorsArray}
                        />
                    </div>

                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <input
                            type="range"
                            min="1"
                            max="20"
                            value={strokeWidth}
                            onChange={handleStrokeWidthChange}
                        />

                        <IconButton onClick={() => handleEraserModeClick( false )}>
                            <DrawIcon style={iconColor}/>
                        </IconButton>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <input
                            type="range"
                            min="1"
                            max="20"
                            value={eraserWidth}
                            onChange={handleEraserWidthChange}
                        />
                        <IconButton onClick={() => handleEraserModeClick( true )}>
                            <FontAwesomeIcon icon={faEraser} style={iconColor}/>
                        </IconButton>

                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <Button onClick={handleDownloadDraw} onTouchEnd={handleDownloadDraw}
                            sx={{
                                width: '274px',
                                height: '45px',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                gap: '10px',
                                background: 'rgba( 243, 230, 0, 1 )',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    color: 'rgba( 243, 230, 0, 1 )',
                                },
                            }}>
                        {t( 'game.label6' )}
                    </Button>
                </Grid>

            </Grid>
        </div>
    );
};
