export default class PrizesDTO {
    id;
    label;
    quantity;

    constructor( data ) {
        if ( data ) {
            this.id = data.id;
            this.label = data.label;
            this.quantity = data.quantity;
        }
    }
}
