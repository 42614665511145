import React from 'react';
import { Container, Toolbar, Typography } from '@mui/material';

export default function Footer(  ){

    const footerStyle = {
        display: 'flex',
        justifyContent: 'center',
    };

    return(
            <Container maxWidth="md">
                <Toolbar style={footerStyle}>
                    <Typography variant="body1" color="white">
                        De Boeck foods © {new Date().getFullYear()}
                    </Typography>
                </Toolbar>
            </Container>
    )
}
