import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import HomeIcon from '@mui/icons-material/Home';
import { AccessTime, SportsSoccer } from '@mui/icons-material';
import whistleSound from '../../../assets/sound/whistle.mp3';
import footballKick from '../../../assets/sound/football_kick.mp3';

function GameField() {
    const navigate = useNavigate();
    const [goalkeeperPosition, setGoalkeeperPosition] = useState( 40 ); // Position initiale du gardien
    const [goalkeeperDirection, setGoalkeeperDirection] = useState( 1 );
    const [ballPosition, setBallPosition] = useState( { x: 50, y: 50 } ); // La balle commence en bas
    const [isShooting, setIsShooting] = useState( false );
    const [score, setScore] = useState( 0 );
    const [timeLeft, setTimeLeft] = useState( 60 );
    const [whistleStart, setWhistleStart] = useState( true );

    const leftGoalStart = 7;
    const leftGoalEnd = 33.33;
    const centerGoalStart = 33.33;
    const centerGoalEnd = 66.67;
    const rightGoalStart = 66.67;
    const rightGoalEnd = 93;

    //TODO maybe refactor ?

    useEffect( () => {

        if ( whistleStart ) {
            new Audio( whistleSound ).play();
            setWhistleStart( false );
        }

        const timerId = setTimeout( () => {
            if ( timeLeft > 0 ) {
                setTimeLeft( timeLeft - 1 );
            } else {
                navigate( '/game/snakyfoot/game-over', { state: { score } } );
            }
        }, 1000 ); // Corrected to 1000 for 1-second intervals
        return () => clearTimeout( timerId );
    }, [timeLeft, navigate, score, whistleStart] );

    useEffect( () => {
        const moveGoalkeeper = () => {
            setGoalkeeperPosition( prevPosition => {
                let newPosition = prevPosition + goalkeeperDirection * 1.3;
                // Inverse la direction aux limites
                if ( newPosition >= 80 || newPosition < 0 ) {
                    setGoalkeeperDirection( -goalkeeperDirection );
                }
                return Math.max( 0, Math.min( 100, newPosition ) ); // Keep position within limit
            } );
        };

        const intervalId = setInterval( moveGoalkeeper, 12 ); // Adjust delay to control speed

        return () => clearInterval( intervalId );
    }, [goalkeeperDirection] );

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'white'
    };

    const handleMouseDown = ( e ) => {
        setIsShooting( true );
    };
    const handleMouseUp = ( e ) => {
        if ( !isShooting ) {
            return;
        }
        const fieldRect = document.querySelector( '.game-field' ).getBoundingClientRect();
        const endX = ((e.clientX - fieldRect.left) / fieldRect.width) * 100;
        const endY = 100 - ((e.clientY - fieldRect.top) / fieldRect.height) * 100; // Calcul inversé pour que le bas soit 0 et le haut 100
        const targetSize = endY > 50 ? 40 : 60; // Simuler l'effet 3D
        executeShoot( endX, endY, targetSize ); // Passer endY aussi
        setIsShooting( false );
    };

    const executeShoot = (endX, endY, targetSize) => {
        const goalkeeperWidth = 45; // Largeur du gardien

        new Audio(footballKick).play();
        setBallPosition({ x: endX, y: endY, size: targetSize });

        // Ajoutons un peu d'intelligence au gardien basée sur la direction de la balle
        // Calculons d'abord la différence entre la position actuelle du gardien et la position de fin de la balle
        let directionTowardBall = endX - goalkeeperPosition;
        // Nous allons normaliser cette direction pour qu'elle soit soit -1, soit 1
        directionTowardBall = directionTowardBall / Math.abs(directionTowardBall) || 0; // Évite NaN si la différence est 0

        // Définissons la nouvelle position du gardien basée sur cette direction
        // On ajoute un peu d'aléatoire pour simuler une prédiction imparfaite
        const newPositionAdjustment = Math.random() * 20 * directionTowardBall; // Ajustement maximal de 20%
        let newPosition = goalkeeperPosition + newPositionAdjustment;
        // S'assurer que le gardien ne sorte pas du terrain
        newPosition = Math.max(0, Math.min(100, newPosition));

        setGoalkeeperDirection(directionTowardBall);
        setGoalkeeperPosition(newPosition);

        setTimeout(() => {
            const isInGoalZone = (endX > leftGoalStart && endX < leftGoalEnd) ||
                (endX > centerGoalStart && endX < centerGoalEnd) ||
                (endX > rightGoalStart && endX < rightGoalEnd);

            const isInHeightRange = endY >= 80 && endY <= 95;

            // Nous devons recalculer la couverture du gardien après son déplacement
            const goalkeeperCoverageStart = Math.max(newPosition - (goalkeeperWidth / 2), 0);
            const goalkeeperCoverageEnd = Math.min(newPosition + (goalkeeperWidth / 2), 100);

            const isGoal = isInGoalZone && isInHeightRange && !((endX >= goalkeeperCoverageStart) && (endX <= goalkeeperCoverageEnd));

            if (isGoal) {
                setScore(score + 1);
            }

            setBallPosition({ x: 50, y: 50, size: 50 }); // Réinitialiser la balle
        }, 1000); // Délai avant de réinitialiser la position de la balle
    };


    const handleMouseMove = ( e ) => {
        if ( !isShooting ) {
            return;
        } // Ignorer le mouvement de la souris sauf si on est en train de tirer

        const fieldRect = document.querySelector( '.game-field' ).getBoundingClientRect();
        const newX = ((e.clientX - fieldRect.left) / fieldRect.width) * 100;
        setBallPosition( { ...ballPosition, x: Math.max( 0, Math.min( 100, newX ) ) } ); // Mettre à jour la position de la balle
    };

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
                  sx={{ margin: '10px' }}>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                    <HomeIcon style={{ color: 'rgba(57, 106, 252, 1)', cursor: 'pointer' }}
                              onClick={() => navigate( '/game/choixdujeu' )}/>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} textAlign="center">
                    <div className="game-field" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
                         onMouseDown={handleMouseDown}>
                        {/* Goalkeeper and ball elements remain unchanged */}
                        <div className="goal">
                            {/* Adding goal zones with a red background for visualization */}
                            <div style={{
                                position: 'absolute',
                                left: '7%',
                                width: '26.33%',
                                height: '100%',
                                //backgroundColor: 'rgba(255, 0, 0, 0.5)'
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                left: '33.33%',
                                width: '33.34%',
                                height: '100%',
                                //backgroundColor: 'rgba(255, 0, 0, 0.5)'
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                left: '66.67%',
                                width: '26.33%',
                                height: '100%',
                                //backgroundColor: 'rgba(255, 0, 0, 0.5)'
                            }}></div>
                            <div className="goalkeeper" style={{
                                left: `${goalkeeperPosition}%` ,
                                //backgroundColor: 'rgba(0,144,232,0.5)'
                            }}></div>
                        </div>
                        <div className="ball" style={{
                            left: `${ballPosition.x}%`,
                            bottom: `${ballPosition.y}%`,
                            width: `${ballPosition.size}px`,
                            height: `${ballPosition.size}px`,
                            /* Ajouter des calculs pour l'ombre ou d'autres effets ici si nécessaire */
                        }}></div>

                        <div className="scoreboard">
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <SportsSoccer style={{ marginRight: '5px' }}/> {score}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AccessTime style={{ marginRight: '5px' }}/> {timeLeft} s
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default GameField;
