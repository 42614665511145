import { alpha, InputBase, styled } from '@mui/material';

const BootstrapInput = styled( InputBase )( ( { theme } ) => ({
    'label + &': {
        marginTop: theme.spacing( 3 ),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create( [
            'border-color',
            'background-color',
            'box-shadow',
        ] ),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'raleway'
        ].join( ',' ),
        '&:focus': {
            boxShadow: `${alpha( theme.palette.primary.main, 0.25 )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default BootstrapInput;
