import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import QuizzLogo from '../../assets/img/grandquizzlogo.png';
import { Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Quizz() {

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'rgba(57, 106, 252, 1)'
    };

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '10px' }}
                  flexDirection="column">
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                    <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                              onClick={() => navigate( '/game/choixdujeu' )}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <img src={QuizzLogo} alt="Logo"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '34px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '38px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '42px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '46px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '50px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >{t( 'game.label4' )}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Typography variant="subtitle1"
                                color="white">{t( 'game.label3' )}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                    <Button type="submit" variant="contained" onClick={() => navigate( '/game/grandquizz/jeu' )}
                            sx={{
                                width: '274px',
                                height: '45px',
                                padding: '10px',
                                borderRadius: '5px',
                                gap: '10px',
                                background: 'rgba( 243, 230, 0, 1 )',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    color: 'rgba( 243, 230, 0, 1 )',
                                },
                            }}>
                        {t( 'game.label5' )}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
