import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    InputLabel,
    Snackbar,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LogoPng from '../assets/img/Logo_snaky.png';
import Footer from './Footer';
import BackgroundSvg from '../assets/img/backgroundStart.svg';
import ClientsDTO from '../model/clients/ClientsDTO';
import BootstrapInput from '../component/BootstrapInput';
import { createClients } from '../services/ClientsService';
import { accountService } from '../_services/account_service';
import { useNavigate } from 'react-router-dom';
import { useMessageUtil } from '../utils/MessageUtil';
import { validateEmail } from '../utils/Utils';
import { isBoolean, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import FlagFR from '../assets/flags/fr.png';
import FlagNl from '../assets/flags/nl.png';

import GagnerSwitch from '../assets/img/Gagner_switch.jpg';

const isNil = require( 'lodash/isNil' );

export default function Start() {

    const [svgBase64, setSvgBase64] = useState( '' );
    const [clients, setClients] = useState( new ClientsDTO() );
    const navigate = useNavigate();
    const { showAlert, alertMessage, alertSeverity, showMessage, handleCloseAlert } = useMessageUtil();
    const [openLangDialog, setOpenLangDialog] = useState( true );

    const { t, i18n } = useTranslation();

    const changeLanguage = ( lng ) => {
        i18n.changeLanguage( lng );
    };

    useEffect( () => {
        // Charger le fichier SVG en tant que texte
        fetch( BackgroundSvg )
        .then( ( response ) => response.text() )
        .then( ( svgText ) => {
            // Encodez le texte du SVG en base64
            const encodedSvg = btoa( svgText );
            setSvgBase64( encodedSvg );
        } )
        .catch( ( error ) => {
            console.error( 'Erreur lors du chargement du SVG :', error );
        } );
    }, [] );

    useEffect( () => {
        // ... (autre code existant)

        if ( !localStorage.getItem( 'i18nextLng' ) ) {
            setOpenLangDialog( true );
        }
    }, [] );

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundImage: `url(data:image/svg+xml;base64,${svgBase64})`,
        backgroundSize: '100%',
    };

    const handleEventEmailChange = ( e ) => {
        setClients( { ...clients, email: e.target.value } );
    };

    const handleEventPostalCodeChange = ( e ) => {
        setClients( { ...clients, postalCode: e.target.value } );
    };

    const handleEventFriterieNameChange = ( e ) => {
        setClients( { ...clients, friterieName: e.target.value } );
    };

    const handleEventFriteriePostalCodeChange = ( e ) => {
        setClients( { ...clients, friteriePostalCode: e.target.value } );
    };

    const _saveClient = async ( e ) => {

        e.preventDefault();

        if ( isNil( clients.email ) || isEmpty( clients.email ) || (clients.email && !validateEmail( clients.email )) ) {
            showMessage( t( 'error.email_incorrect' ), 'error' );
            return;
        }

        if ( isNil( clients.postalCode ) || isEmpty( clients.postalCode ) ) {
            showMessage( t( 'error.postal_code_empty' ), 'error' );
            return;
        }

        if ( isNil( clients.friterieName ) || isEmpty( clients.friterieName ) ) {
            showMessage( t( 'error.friterie_name_empty' ), 'error' );
            return;
        }

        if ( isNil( clients.friteriePostalCode ) || isEmpty( clients.friteriePostalCode ) ) {
            showMessage( t( 'error.friterie_postal_code_empty' ), 'error' );
            return;
        }

        if ( isNil( clients.consentStatus ) || (isBoolean( clients.consentStatus ) && clients.consentStatus === false) ) {
            showMessage( t( 'error.privacy_policy_no_checked' ), 'error' );
            return;
        }

        if ( isNil( clients.parentalConsent ) || (isBoolean( clients.parentalConsent ) && clients.parentalConsent === false) ) {
            showMessage( t( 'error.parent_give_consent' ), 'error' );
            return;
        }

        let result = await createClients( clients );
        if ( !result.error ) {
            accountService.saveEmail( clients.email );
            // Redirect to the home page
            navigate( '/game/choixdujeu' );
        } else {
            // Show error message
            showMessage( t( 'error.creation_account' ), 'error' );

        }
    };

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '10px' }}
                  flexDirection="column">
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <img src={LogoPng} alt="Logo"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <form>
                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'common.email' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                type="email"
                                value={clients.email}
                                onChange={handleEventEmailChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.postal_code' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clients.postalCode}
                                onChange={handleEventPostalCodeChange}
                                fullWidth
                                required
                            />
                        </FormControl>

                        <FormControl variant="standard" fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.friterie_name' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clients.friterieName}
                                onChange={handleEventFriterieNameChange}
                                fullWidth
                                required
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel
                                shrink
                                htmlFor="bootstrap-input"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {t( 'client.friterie_postal_code' )}
                            </InputLabel>
                            <BootstrapInput
                                id="bootstrap-input"
                                variant="standard"
                                size="small"
                                value={clients.friteriePostalCode}
                                onChange={handleEventFriteriePostalCodeChange}
                                fullWidth
                                required
                            />
                        </FormControl>
                        <Grid item sx={{ textAlign: 'left' }}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            onChange={e => setClients( {
                                                ...clients,
                                                consentStatus: e.target.checked
                                            } )}
                                        />
                                    }
                                    label={t( 'start.accept_privacy_policy' )}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            onChange={e => setClients( {
                                                ...clients,
                                                parentalConsent: e.target.checked
                                            } )}
                                        />
                                    }
                                    label={t( 'start.accept_child' )}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={_saveClient}
                                sx={{
                                    width: '274px',
                                    height: '45px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    gap: '10px',
                                    background: 'rgba( 243, 230, 0, 1 )',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        color: 'rgba( 243, 230, 0, 1 )',
                                    },
                                }}>
                                {t( 'common.submit' )}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <img
                        src={GagnerSwitch}
                        alt="Gagner une Switch"
                        style={{
                            maxWidth: '100%',  // Responsive : l'image prend jusqu'à 100% de la largeur de son conteneur
                            height: 'auto',    // La hauteur s'ajuste automatiquement pour garder le ratio
                            width: '640px',    // Largeur maximale de 640px
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} style={{ marginBottom: '10px' }}>
                    <Typography
                        variant="h1"
                        textAlign="left"
                        letterSpacing="0em"
                        sx={{
                            fontSize: {
                                xs: '14px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                sm: '16px', // Taille de police pour les écrans de taille 'sm' (small)
                                md: '18px', // Taille de police pour les écrans de taille 'md' (medium)
                                lg: '18px', // Taille de police pour les écrans de taille 'lg' (large)
                                xl: '18px', // Taille de police pour les écrans de taille 'xl' (extra large)
                            },
                        }}
                    >
                        <span style={{ color: '#ffffff' }}>
                            {t( 'game.label30' )}
                        </span>
                        <span style={{ color: '#FF6F61', fontWeight: 'bold' }}>
                            {t( 'game.label31' )}
                        </span>
                        <span style={{ color: '#ffffff' }}>
                            {t( 'game.label32' )}
                        </span>
                        <span style={{ color: '#00BFFF', fontWeight: 'bold' }}>
                            {t( 'game.label33' )}
                        </span>
                        <div style={{ color: '#e4e4e4', fontSize: '0.7em', marginTop: '10px' }} >
                            <a href="https://snaky.be/concours-reglement/" target="_blank" rel="noreferrer">{t( 'game.label34' )}</a>
                        </div>
                    </Typography>
                </Grid>
            </Grid>
            <Footer/>

            {/*POPUP LANGUAGE*/}
            <Dialog
                open={openLangDialog}
                onClose={() => setOpenLangDialog( false )}
                aria-labelledby="language-dialog-title"
                aria-describedby="language-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: '12px',
                        backgroundColor: '#F3E600FF'  // une couleur douce et enfantine
                    }
                }}
            >
                <DialogContent style={{ textAlign: 'center', fontFamily: 'Comic Sans MS, cursive, sans-serif' }}>
                    <DialogContentText id="language-dialog-description" style={{ fontSize: '18px' }}>
                        {t( 'common.please_select_language' )}
                    </DialogContentText>
                    <div style={{ display: 'flex', justifyContent: 'space-around', margin: '20px 0' }}>
                        <Button
                            onClick={() => {
                                changeLanguage( 'nl' );
                                setOpenLangDialog( false );
                            }}
                            color="primary"
                            startIcon={<img src={FlagNl} alt="Nederlands" style={{ width: '40px' }}/>}
                        >
                            Nederlands
                        </Button>
                        <Button
                            onClick={() => {
                                changeLanguage( 'fr' );
                                setOpenLangDialog( false );
                            }}
                            color="primary"
                            startIcon={<img src={FlagFR} alt="Français" style={{ width: '40px' }}/>}
                        >
                            Français
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/* Le composant Snackbar pour afficher l'alerte */}
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>

    );
}
