let saveEmail = (email) => {
    localStorage.setItem('email', email)
}
let isLogged = () => {
    let email = localStorage.getItem('email');
    return !!email;
}

let getEmail = () => {
    return localStorage.getItem('email');
}
export const accountService = {
    saveEmail, isLogged, getEmail
}
