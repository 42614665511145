import React from 'react';
import {Route, Routes} from "react-router-dom";
import Start from "./Start";
import ErrorPage from "./ErrorPage";

const StartRouter = () => {
    return (
        <Routes>
            <Route index element={<Start/>}/>
            <Route path="start" element={<Start/>}/>
            <Route path="*" element={<ErrorPage/>}/>
        </Routes>
    );
};

export default StartRouter;