import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import QuizzLogo from '../../assets/img/grandquizzlogo.png';
import { Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function QuizzResult( {
                                         score,
                                         questionLength,
                                         restartGame
                                     } ) {

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Fonction pour calculer le pourcentage du score/questionLength
    const calculatePercentage = ( score, questionLength ) => {
        const percentage = (score / questionLength) * 100;
        return percentage.toFixed( 2 ); // Arrondi à 2 décimales
    };

    // Fonction pour renvoyer le message en fonction du pourcentage
    const getMessage = ( percentage ) => {
        if ( percentage >= 80 ) {
            return t( 'game.label10' );
        } else if ( percentage >= 60 ) {
            return t( 'game.label11' );
        } else if ( percentage >= 40 ) {
            return t( 'game.label12' );
        } else {
            return t( 'game.label13' );
        }
    };

    const percentage = calculatePercentage( score, questionLength );
    const message = getMessage( percentage );

    return (
        <Grid container justifyContent="center" alignItems="center" flexDirection="column" spacing={2}
              sx={{ margin: '10px' }}>

            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'left' }}>
                <HomeIcon style={{ color: 'white', cursor: 'pointer' }}
                          onClick={() => navigate( '/game/choixdujeu' )}/>
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center' }}>
                    <img src={QuizzLogo} alt="Logo"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '30px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '32px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '33px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '34px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '35px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >
                        {message}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Typography variant="h1"
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '30px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '32px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '33px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '34px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '35px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}
                    >

                        {t( 'game.label14' ) + percentage} %
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Typography variant="subtitle1"
                                color="white">{t( 'game.label15' ) + score + t( 'game.label16' ) + questionLength}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>
                    <Button type="submit" variant="contained" onClick={() => restartGame()}
                            sx={{
                                width: '274px',
                                height: '45px',
                                padding: '10px',
                                borderRadius: '5px',
                                gap: '10px',
                                background: 'rgba( 243, 230, 0, 1 )',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    color: 'rgba( 243, 230, 0, 1 )',
                                },
                            }}>
                        {t( 'game.label17' )}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
