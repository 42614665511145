import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
    const { t } = useTranslation();

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>{t( 'error.msg_pageError' )}</p>
        </div>
    );
}
